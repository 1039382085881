main.why-kumon
	section.introduction
		padding 50px 0
		+from-width($mobile_breakpoint)
			padding 100px 0
		.content-wrapper
			cf()
			.text
				+from-width($mobile_breakpoint)
					col(0.6)
				p
					font-size 20px
					max-width 740px
					line-height 30px
					+from-width($mobile_breakpoint)
						margin-top 50px
			.image
				+from-width($mobile_breakpoint)
					col(0.4)
				img
					margin 0 auto
	section.reasons-choose-kumon
		background-color $grey
		padding 50px 0
		+from-width($mobile_breakpoint)
			padding 70px 0
		.content-wrapper
			>h2
				text-align center
				margin-bottom 40px
				span
					color $pink !important
			.reasons-big-wrapper
				cf()
				margin-bottom 70px
				+from-width($mobile_breakpoint)
					margin-bottom 0
				.reason-wrapper, .reason-image
					+from-width($mobile_breakpoint)
						col(1/2)
				.reason-image
					img
						margin 0 auto
				.reason-wrapper
					position relative
					text-align center
					margin-bottom 20px
					+from-width($mobile_breakpoint)
						margin-bottom 0
					button.slide-left, button.slide-right
						border-radius 100px
						display inline-block
						background-color $orange
						text-align center
						color #fff
						border none
						outline none
						font-size 20px
						padding-top 10px
						margin 0 5px
						width 50px
						height 50px
						position relative
						+from-width($mobile_breakpoint)
							width 70px
							font-size 30px
							height 70px
						&:after
							absolute top 50%
							transform translateY(-50%)
							color $orange
							font-family 'AzoSans Bold', sans-serif
							font-size 14px
					button.slide-left
						&:after
							left -70px
							content 'Previous'
					button.slide-right
						&:after
							right -45px
							content 'Next'
					.reason-content-slider
						.reason
							text-align center
							+from-width($mobile_breakpoint)
								padding-top 90px
							.icon
								center(140px)
								margin-bottom 30px
								+from-width($mobile_breakpoint)
									max-width 200px
							h2, p
								center(600px)
							h2
								margin-bottom 20px
								+from-width($mobile_breakpoint)
									margin-bottom 40px
									font-size 36px
									line-height 36px
							img
								margin 0 auto
	section.kumon-founder
		padding 50px 0
		+from-width($mobile_breakpoint)
			padding 100px 0
		.content-wrapper
			cf()
			.image, .textbox
				+from-width($mobile_breakpoint)
					span(1/2)
			.image
				margin-bottom 20px
				+from-width($mobile_breakpoint)
					margin-bottom 0
				img
					margin 0 auto
			.textbox
				+from-width($mobile_breakpoint)
					padding 60px 0
				.blockquote
					center(410px)
					margin-bottom 30px
					img.top
						margin-bottom 20px
					img.btm
						transform rotate(180deg)
					p
						text-align center
						&:nth-child(2)
							font-size 20px
					h4
						text-align center
						color #757F8F
				>p
					center(410px)
					text-align left
	section.kumon-child
		padding 20px 0
		+from-width($mobile_breakpoint)
			padding 50px 0
			margin-bottom 80px
		.content-wrapper
			.title
				cf()
				margin-bottom 30px
				+from-width($mobile_breakpoint)
					margin-bottom 50px
				.image
					col(0.2)
					+from-width($mobile_breakpoint)
						width 105px
						margin-right 2%
				.text
					col(0.8)
					+from-width($mobile_breakpoint)
						col(0.7)
					h2
						margin-bottom 10px
						+from-width($mobile_breakpoint)
							margin-bottom 30px
					p
						margin-bottom 0
			.characteristics
				cf()
				.characteristic
					cf()
					stack()
					margin-bottom 40px
					padding-bottom 30px
					border-bottom 2px solid $blue
					+from-width($mobile_breakpoint)
						unstack()
						col(1/4, gutter:2, cycle:4)
						padding-bottom 60px
						margin-bottom 30px
					&:last-child
						border-bottom none
						+from-width($mobile_breakpoint)
							width 49%
							// padding-top 10px
							padding-bottom 0
						a.vbox
							cf()
							center(420px)
							border-radius 10px
							border 4px solid $grey
							padding 15px 20px
							.thumbnail
								position relative
								+from-width($mobile_breakpoint)
									col(0.38, gutter:5)
								.play-icon
									background-color $orange
									width 48px
									height 48px
									color #fff
									font-size 20px
									padding-top 13px
									text-align center
									absolute top 50% left 50%
									border-radius 100px
									transform translate(-50%, -50%)
							.text
								width 100%
								text-transform uppercase
								font-size 14px
								font-family 'AzoSans Bold', sans-serif
								letter-spacing 0.7px
								line-height 24px
								color #757F8F
								text-align center
								padding-top 25px
								+from-width($mobile_breakpoint)
									col(0.62, gutter:5)
					.image
						col(0.2)
					.text
						col(0.8)
						h3
							letter-spacing -.5px
							+from-width($mobile_breakpoint)
								font-size 24px
								line-height 24px
							br
								display none
								+from-width($mobile_breakpoint)
									display block
	section.testimonials
		margin-top 0
		padding-bottom 100px
		.testimonial
			.title
				+from-width($mobile_breakpoint)
					min-height 60px