::-webkit-input-placeholder
	color alpha($darkBlue, 0.5)

a.enroll-btn
	fixed bottom 20px right 20px
	background-color #fff
	border-radius 30px
	font-size 14px
	display inline-block
	z-index 5
	font-family 'AzoSans Bold', sans-serif
	border 4px solid $darkBlue
	color $darkBlue
	text-align center
	letter-spacing 0.8px
	padding 15px 50px 15px 20px
	box-shadow 0 2px 10px 0 rgba(0,0,0,0.25)
	&:after
		content ''
		width 48px
		height 50px
		background url('../img/pencil-icon.svg') no-repeat top left
		background-size cover
		absolute bottom 10px right -5px
		transition 0.3s ease-in-out
	&.opened
		background-color $darkBlue
		color #fff
		padding 26px
		border-radius 100px
		span
			display none
		&:after
			width 43px
			height 43px
			background url('../img/close-icon.svg') no-repeat center
			background-size contain
			absolute top 10px left 50%
			bottom initial
			right initial
			transform translateX(-50%)

.enroll-form, .franchise-form, .promotion-form
	border-radius 10px
	background-color #fff
	padding 30px 20px
	z-index 1000
	box-shadow 0 4px 10px rgba(0, 0, 0, 0.25)
	width 95%
	max-width 480px
	fixed bottom 85px left 50%
	transform translateX(-50%)
	height 400px
	overflow auto
	display none
	-webkit-overflow-scrolling touch
	+tablet()
		width 100%
		height auto
		min-height 650px
		// height 650px
		padding 30px
		transform translateX(0%)
		right 20px
		left initial
	+at(1024px)
		height 650px
	+desktop()
		height 500px
		padding 30px
		transform translateX(0%)
		right 20px
		left initial
	.promo-banner
		margin-bottom 20px
	h3
		margin-bottom 20px
		letter-spacing -.5px
		font-size 20px
		line-height 20px
		+from-width($mobile_breakpoint)
			font-size 24px
			line-height 24px
	.row
		margin-bottom 3px
		span.wpcf7-not-valid-tip
			margin 5px 0 10px 0
			font-size 12px
		&.tnc
			cf()
			margin-bottom 20px
			input[type=checkbox], label
				display inline-block
				vertical-align middle
			input[type=checkbox]
				outline none
				width 20px
				height 20px
				border-radius 6px
				border 2px solid #E6EBF3
				-webkit-appearance none
				transition 0.3s ease-in-out
				margin-right 10px
				position relative
				+from-width($mobile_breakpoint)
					width 36px
					height 36px
				&:after
					content '\f00c'
					font-family FontAwesome, sans-serif
					font-size 16px
					transform scale(0)
					transition 0.3s ease-in-out
					absolute top left
					+from-width($tablet_breakpoint)
						font-size 30px
				&:checked
					&:after
						transform scale(1)
			label
				font-size 12px
				cursor pointer
				a
					color $blue
					font-family 'AzoSans Bold', sans-serif
					&:hover
						.no-touch &
							text-decoration underline
			.wpcf7-list-item
				margin 0
				.wpcf7-list-item-label
					vertical-align middle
					display inline-block
					width calc(100% - 46px)
		&.label-text
			font-size 12px
			margin-bottom 15px
		input[type=text], input[type=email], select, textarea
			outline none
			background-color #fff
			width 100%
			border 2px solid #E6EBF3
			padding 12px 15px
			border-radius 4px
		textarea
			max-width 100%
		select
			color alpha($darkBlue, 0.5)
			-webkit-appearance none
			height 45px
			background url('../img/dropdown-arrow.svg') no-repeat 95% 50%
	.wpcf7-response-output
		border none
		padding 10px 0
		margin 0
	input[type=submit]
		background-color $darkBlue
		text-align center
		width 100%
		color #fff
		height 40px
		font-size 14px
		font-family 'AzoSans Bold', sans-serif
		letter-spacing 0.8px
		border-radius 6px
		border none
		&[disabled]
			background-color alpha($darkBlue, 0.5)

.franchise-form
	z-index 10
	left initial
	transform translateX(0)
	display block
	max-width 410px
	padding 50px 35px
	width 100%
	height auto
	+below($mobile_breakpoint)
		position static
		margin 40px auto 0 auto
		box-shadow none
		padding 0 20px
	+from-width($mobile_breakpoint)
		absolute bottom -80px right 40px
	.cta-wrapper
		text-align right
		input[type=submit]
			width 114px
			display inline-block
.promotion-form
	margin-top 80px
	transform translateX(0)
	height auto
	width 100%
	display block
	position static
	top initial
	left initial
	right initial
	bottom initial
	padding 0
	box-shadow none
	+from-width($mobile_breakpoint)
		max-width 70%
		padding 0 90px

main.thankyou
	.content-wrapper
		padding-top 50px
		+from-width($mobile_breakpoint)
			margin-bottom 20px
			padding-top 150px
		h2
			margin-bottom 20px