// .page-template-template-programmes
main.programmes
	&.maths
		section.testimonials
			background-color $green
	section.programme-content
		padding 40px 0 40px 0
		+from-width($mobile_breakpoint)
			padding 80px 0 0px 0
		.content-wrapper
			.programme-summary-wrapper
				cf()
				margin-bottom 20px
				+from-width($mobile_breakpoint)
					margin-bottom 40px
				.image
					margin-bottom 30px
					+from-width($mobile_breakpoint)
						col(0.4)
						margin-bottom 0
					img
						margin 0 auto
				.content
					+from-width($mobile_breakpoint)
						col(0.6)
					h2
						margin-bottom 30px
			.programme-gallery, .programme-outline
				padding 20px 0
				+from-width($mobile_breakpoint)
					padding 50px 0
				img
					margin 0 auto
			.programme-outline
				padding-bottom 0
				h3, p
					text-align center
				h3
					margin-bottom 20px
				.topics-list
					cf()
					border-top 2px solid $blue
					border-bottom 2px solid $blue
					margin-top 40px
					padding 40px 0
					.topics
						text-align center
						margin-bottom 20px
						+from-width($mobile_breakpoint)
							col(1/3)
							text-align center
							margin-bottom 0
						&:last-child
							margin-bottom 0
						ul
							margin 0
							padding 0
							list-style none
							li
								font-family 'AzoSans Bold', sans-serif
								color #757f8f
								width 100%
								font-size 16px
								+from-width($mobile_breakpoint)
									font-size 14px
								span
									color $blue
	section.testimonials
		padding 40px 0
		background-color $purple
		position relative
		.content-wrapper
			text-align center
			.icon
				width 170px
				absolute top -85px left 50%
				transform translateX(-50%)
			h2
				margin 60px 0 30px 0
			iframe
				margin-bottom 40px
				height 190px
				// height 300px
				+from-width($mobile_breakpoint)
					height 540px
					width 75%