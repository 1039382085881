section.header-banner
	.banner
		position relative
		min-height 400px
		+from-width($mobile_breakpoint)
			min-height 600px
		video
			display none
			+from-width($mobile_breakpoint)
				absolute top left
				min-width 100%
				min-height 100%
				display block
		.bg
			absolute top left
			width 100%
			height 100%
			display none
			+from-width($mobile_breakpoint)
				display block
		.content
			width 80%
			z-index 3
			absolute top 50% left 50%
			transform translate(-50%, -50%)
			h1
				+below($mobile_breakpoint)
					font-size 28px
					line-height 1.3
			h3
				margin-bottom 40px
			h1, h2
				text-shadow 0 2px 10px rgba(0, 0, 0, 0.3)
				margin-bottom 20px
				br
					display none
					+tablet()
						display block
					+desktop()
						display block
			p
				max-width 520px
				margin-bottom 20px

.home
	section.header-banner
		.banner
			+from-width($mobile_breakpoint)
				background-position center center
.english
	section.header-banner
		.banner
			// background url('../img/english-header-banner-mo.jpg') no-repeat top center
			// background-size cover
			.content h1
				text-shadow none
.maths
	section.header-banner
		.banner
			// background url('../img/maths-header-banner-mo.jpg') no-repeat top center
			// background-size cover
			.content h1
				text-shadow none
.promotion
	section.header-banner
		.banner
			// background url('../img/promo-header-banner.jpg') no-repeat top right
			// background-size cover
			background-position top right
			+from-width($mobile_breakpoint)
				min-height 360px
			.content
				+from-width($mobile_breakpoint)
					width 72%
.become-our-franchise
	section.header-banner
		.banner
			// background url('../img/franchise-header-banner-mo.jpg') no-repeat top center
			// background-size cover
.why-kumon
	section.header-banner
		.banner
			// background url('../img/why-kumon-header-banner-mo.jpg') no-repeat top center
			// background-size cover
.news-activities-inner
	section.header-banner
		.banner
			// background url('../img/news-activities/ceo-header-banner.jpg') no-repeat top center
			// background-size cover
			+from-width($mobile_breakpoint)
				min-height 360px
			.content
				+from-width($mobile_breakpoint)
					width 72%