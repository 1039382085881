main.home
	section.feature-tiles
		.content-wrapper
			padding-top 20px
			padding-bottom 20px
			.card
				display block
				position relative
				padding 30px
				+from-width($mobile_breakpoint)
					overflow hidden
				&:hover
					.no-touch &
						.bg
							transform scale(1.15)
				.bg
					width 100%
					height 100%
					absolute top left
					z-index -1
					transition 1s ease-in-out
			.row
				cf()
				&:first-child
					.two-third
						.card
							min-height 400px
							+from-width($mobile_breakpoint)
								height 490px
							.bg
								background url('../img/home-kumon-learning.jpg') no-repeat top right
								background-size cover
							.sub-header
								border-top 2px solid #fff
								padding-top 10px
								absolute bottom 30px left 30px
								+from-width($mobile_breakpoint)
									width 43%
									bottom 100px
								h2, p
									display inline-block
									vertical-align middle
								h2
									line-height 1.4
									font-size 26px
								p
									margin-bottom 0
									font-size 22px
									&.light
										font-family 'Futura Extra Bold', sans-serif
					.one-third
						.card
							min-height 235px
							&:first-child
								margin-bottom 20px
								.bg
									background url('../img/home-maths.jpg') no-repeat top right
									background-size cover
							&:last-child
								.bg
									background url('../img/home-english.jpg') no-repeat top right
									background-size cover
				&:nth-child(2)
					.one-third
						.card
							min-height 400px
							text-align center
							+from-width($mobile_breakpoint)
								height 100%
							.bg
								background url('../img/home-math-reading-method.jpg') no-repeat top center
								background-size cover
							a
								absolute top 50% left 50%
								transform translate(-50%, -50%)
								h3
									margin-top 20px
									text-shadow 0 2px 10px rgba(0, 0, 0, 0.3)
									font-size 24px
									line-height 24px
									letter-spacing -.5px
					.two-third
						.card
							padding 0
				&:nth-child(3)
					margin-bottom 20px
					.card
						min-height 400px
						text-align center
						padding 10px 20px
						+from-width($mobile_breakpoint)
							height 640px
						h2
							margin-bottom 40px
							text-shadow 0 2px 10px rgba(0, 0, 0, 0.50)
							br
								display none
								+from-width($mobile_breakpoint)
									display block
						.bg
							background url('../img/home-360.jpg') no-repeat top center
							background-size cover
						>img
							margin 20px auto
							+from-width($mobile_breakpoint)
								margin 60px auto 80px auto
				&:nth-child(4)
					.card
						background linear-gradient(90deg, #FA92A9 0%, #D32152 100%)
						display flex
						flex-direction column
						padding 30px 20px 0 20px
						+from-width($mobile_breakpoint)
							padding 0
							min-height 260px
							flex-direction row
						.image
							order 3
							+from-width($mobile_breakpoint)
								order 1
								padding 0 20px
								width 35%
								position relative
							img
								margin 0 auto
								+from-width($mobile_breakpoint)
									absolute bottom 0 left 10px
									max-width 95%
						.text
							text-align center
							order 1
							+from-width($mobile_breakpoint)
								order 2
								text-align left
								width 45%
								padding 80px 0
						.cta
							order 2
							text-align center
							padding 30px 0 40px 0
							+from-width($mobile_breakpoint)
								order 3
								text-align right
								width 20%
								padding 120px 40px 120px 0
				.two-third
					margin-bottom 20px
					+from-width($mobile_breakpoint)
						col(2/3, gutter:2)
				.one-third
					margin-bottom 20px
					+from-width($mobile_breakpoint)
						col(1/3, gutter:2)