
@font-face {
    font-family 'AzoSans Bold'
    src local('../fonts/AzoSans-Bold'),
        url('../fonts/AzoSans-Bold.woff') format('woff'),
        url('../fonts/AzoSans-Bold.svg') format('svg'),
        url('../fonts/AzoSans-Bold.eot') format('eot')
    font-weight normal
    font-style normal
}

@font-face {
    font-family 'AzoSans Medium'
    src local('../fonts/AzoSans-Medium'),
        url('../fonts/AzoSans-Medium.woff') format('woff'),
        url('../fonts/AzoSans-Medium.svg') format('svg'),
        url('../fonts/AzoSans-Medium.eot') format('eot')
    font-weight normal
    font-style normal
}

@font-face {
    font-family 'AzoSans Regular'
    src local('../fonts/AzoSans-Regular'),
        url('../fonts/AzoSans-Regular.woff') format('woff'),
        url('../fonts/AzoSans-Regular.svg') format('svg'),
        url('../fonts/AzoSans-Regular.eot') format('eot')
    font-weight normal
    font-style normal
}

@font-face {
    font-family 'Futura Extra Bold'
    src local('../fonts/FuturaStd-ExtraBold'),
        url('../fonts/FuturaStd-ExtraBold.woff') format('woff'),
        url('../fonts/FuturaStd-ExtraBold.svg') format('svg'),
        url('../fonts/FuturaStd-ExtraBold.eot') format('eot')
    font-weight normal
    font-style normal
}