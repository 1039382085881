main.find-a-center
	.content-wrapper
		padding-top 40px
		padding-bottom 40px
		+from-width($mobile_breakpoint)
			padding-top 90px
			padding-bottom 90px
		h2
			margin-bottom 20px
		input
			outline none !important
		.custom-address-wrapper
			cf()
			margin-bottom 20px
			.custom-address
				col(0.65)
				font-size 12px
				color #757F8F
				line-height 18px
				letter-spacing .5px
				+from-width($mobile_breakpoint)
					col(0.7)
			.custom-direction
				col(0.35)
				position relative
				border-left 1px solid $grey
				padding 15px 0 20px 15px
				+from-width($mobile_breakpoint)
					col(0.3)
					padding-left 20px
					padding-bottom 15px
				a.wpsl-directions:after
					content ''
					width 78px
					height 51px
					background url('../img/directions-icon.svg') no-repeat top center #fff
					absolute top 50% left 15px
					transform translateY(-50%)
		.wpsl-more-info-listings
			display block
			.description
				color #757F8F
			.custom-phone
				cf()
				position relative
				margin-bottom 20px
				.icon
					col(0.1)
					width 20px
				.phone
					col(0.9)
					margin-right 0
					a
						color #757F8F
						font-family 'AzoSans Regular', sans-serif
						font-size 12px
						letter-spacing .5px
				>a.whatsapp
					// col(0.3)
					absolute top 0 left 150px
					+from-width($mobile_breakpoint)
						absolute top 0 left 120px
		.wpsl-store-hours
			cf()
			.icon
				col(0.1)
				width 20px
			.hours
				col(0.9)
				table
					td
						color #757F8F
						font-family 'AzoSans Regular', sans-serif
						font-size 12px
						letter-spacing .5px
						line-height 18px
		#wpsl-wrap
			.wpsl-gmap-canvas
				display none
				+from-width($mobile_breakpoint)
					display block
			.wpsl-search
				padding 12px
				background-color $grey
				+from-width($mobile_breakpoint)
					border-radius 50px
					margin-bottom 30px
				.wpsl-select-wrap
					margin-top 15px
					+from-width($mobile_breakpoint)
						margin-top 0
				.wpsl-input
					+from-width($mobile_breakpoint)
						margin-left 10px
					label
						+from-width($mobile_breakpoint)
							margin-top 2px
				.wpsl-select-wrap
					label
						+from-width($mobile_breakpoint)
							margin-top 5px
				.wpsl-input, .wpsl-select-wrap
					margin-bottom 0
					+from-width($mobile_breakpoint)
						margin-top 10px
			#wpsl-gmap
				+from-width($mobile_breakpoint)
					width 65%
					// height 640px !important
			#wpsl-result-list
				width 100%
				+from-width($mobile_breakpoint)
					width 33%
			#wpsl-stores
				border 8px solid #E6EBF3
				+from-width($mobile_breakpoint)
					// height 640px !important
				ul
					li
						padding 20px
						.wpsl-store-location
							p
								>strong
									font-size 18px
									color $darkBlue
									font-family 'Futura Extra Bold', sans-serif
									line-height 24px
									margin-bottom 20px
									display block
								span
									color #757F8F
									font-size 12px
									line-height 18px
									letter-spacing 0.5px
		.wpsl-search-btn-wrap
			margin-right 3px !important
			+from-width($mobile_breakpoint)
				float right !important
		#wpsl-search-btn
			border-radius 50px
			margin-right 0
			color #fff
			float right
			background-image unset
			border none
			font-size 14px
			font-family 'AzoSans Bold', sans-serif
			text-transform uppercase !important
			font-weight normal
			padding 15px 35px
			display inline-block
			letter-spacing 0.8px
			background-color $orange