main.become-our-franchise
	section.franchise-form-wrapper
		position relative
	section.introduction
		padding 0 0 40px 0
		+from-width($mobile_breakpoint)
			padding 80px 0
		.content-wrapper
			p
				max-width 740px
				margin-top 50px
				+from-width($mobile_breakpoint)
					font-size 20px
			.badges
				cf()
				padding 20px 0
				max-width 1200px
				margin 0 auto
				+from-width($mobile_breakpoint)
					padding 40px 0
				.badge
					col(1/2, cycle:2)
					margin-bottom 30px
					+from-width($mobile_breakpoint)
						col(1/4, uncycle:2)
						margin-bottom 0
					img
						margin 0 auto
			.top-badge
				center(70%)
				+from-width($mobile_breakpoint)
					max-width 80%
				img
					margin 0 auto 20px auto
					+from-width($mobile_breakpoint)
						width 25%
						margin 0 30px 20px 30px
						display inline-block
						vertical-align middle
	section.why-kumon
		background-color $grey
		padding 40px 0
		+from-width($mobile_breakpoint)
			padding 120px 0
		.content-wrapper
			h2, p
				text-align center
			>h2
				margin-bottom 30px
			.reasons
				cf()
				margin-top 70px
				+from-width($mobile_breakpoint)
					margin 70px auto
				.reason
					stack()
					margin-bottom 30px
					+from-width($mobile_breakpoint)
						unstack()
						col(1/4)
						margin-bottom 0
					.icon
						margin-bottom 35px
						img
							margin 0 auto
							+from-width($mobile_breakpoint)
								margin 0
					.title
						cf()
						padding-bottom 20px
						margin-bottom 30px
						border-bottom 2px solid $blue
						h2
							col(0.1)
							+from-width($mobile_breakpoint)
								col(0.2)
						h3
							col(0.9)
							padding-top 3px
							+from-width($mobile_breakpoint)
								col(0.8)
								padding-top 0
								font-size 24px
								line-height 24px
							br
								display none
								+from-width($mobile_breakpoint)
									display block
					p
						text-align left
					ol
						margin 0
						padding-left 20px
						li
							margin-bottom 10px
							color #757F8F
	section.kumon-instructor
		position relative
		padding-bottom 50px
		+from-width($mobile_breakpoint)
			padding-bottom 100px
		&:after
			content ''
			width 100%
			height 250px
			absolute top left
			background-color $grey
			z-index -1
		.content-wrapper
			h2
				text-align center
				margin-bottom 20px
				+from-width($mobile_breakpoint)
					margin-bottom 50px
			.video-wrapper
				cf()
				center(1290px)
				a.video
					overflow hidden
					display block
					position relative
					margin-bottom 20px
					+from-width($mobile_breakpoint)
						col(1/2)
						margin-bottom 0
					&:hover
						.no-touch &
							img
								transform scale(1.5)
					img
						transform scale(1.34)
						margin 0 auto
						transition 1s ease-in-out
					.play-icon
						absolute top 50% left 50%
						transform translate(-50%, -50%)
						width 70px
						height 70px
						border-radius 100px
						background-color $orange
						text-align center
						color #fff
						padding-top 20px
						.fa
							color inherit
							font-size 30px
	section.franchise-requirement
		+from-width($mobile_breakpoint)
			padding 50px 0
		.content-wrapper
			.title
				cf()
				+from-width($mobile_breakpoint)
					margin-bottom 30px
				.icon
					col(0.2)
					+from-width($mobile_breakpoint)
						width 151px
				.text
					col(0.8)
					+from-width($mobile_breakpoint)
						col(0.7)
					h2
						margin-bottom 20px
			.requirements
				cf()
				.requirement
					stack()
					margin-bottom 30px
					+from-width($mobile_breakpoint)
						unstack()
						col(1/3)
						margin-bottom 0
					h2
						border-bottom 2px solid $blue
						padding-bottom 20px
						margin-bottom 30px
					p
						font-size 16px
						+from-width($mobile_breakpoint)
							font-size 20px
	section.recruitment-process
		+from-width($mobile_breakpoint)
			padding 50px 0
		.content-wrapper
			>h2
				margin-top 60px
				margin-bottom 30px
			>p
				max-width 850px
				margin-bottom 50px
				+from-width($mobile_breakpoint)
					margin-bottom 80px
			.recruitment-processes
				cf()
				.recruitment-process
					stack()
					margin-bottom 40px
					+from-width($mobile_breakpoint)
						unstack()
						col(1/3, cycle:3)
						margin-bottom 60px
					.title
						cf()
						border-bottom 2px solid $blue
						padding-bottom 30px
						margin-bottom 30px
						h2, h3
							letter-spacing -.5px
						h2
							col(0.1)
							+from-width($mobile_breakpoint)
								col(0.15)
						h3
							col(0.9)
							+from-width($mobile_breakpoint)
								col(0.85)
								font-size 24px
								line-height 24px
					p a
						font-family 'AzoSans Bold', sans-serif
						&:hover
							.no-touch &
								text-decoration underline
	section.faqs
		background-color $grey
		padding 50px 0 20px 0
		.content-wrapper
			>h2
				text-align center
				margin-bottom 40px
				+from-width($mobile_breakpoint)
					margin-bottom 60px
			.faq-wrapper
				center(1068px)
				margin-bottom 100px
				.row
					border-bottom 4px solid #C7CED9
					padding 30px 20px
					+from-width($mobile_breakpoint)
						padding 30px 50px
					&:first-child
						border-top 4px solid #C7CED9
					a.question
						position relative
						padding-left 40px
						display block
						+from-width($mobile_breakpoint)
							padding-left 60px
						&:before, &:after
							content ''
							width 24px
							height 2px
							background-color $blue
							absolute top 12px left 0px
						&:after
							transition 0.3s ease-in-out
							transform rotate(90deg)
						&.expand
							&:after
								opacity 0
						p
							font-size 20px
							line-height 30px
							margin-bottom 0px
					.answer
						margin-top 20px
						padding-left 40px
						display none
						+from-width($mobile_breakpoint)
							padding-left 60px
						ul
							margin 0
							padding-left 20px
							li
								line-height 30px
								color #757F8F
						p
							color #757F8F
			.banner
				margin-top 20px
				.card
					background linear-gradient(90deg, #FA92A9 0%, #D32152 100%)
					display flex
					flex-direction column
					padding 30px 20px 0 20px
					+from-width($mobile_breakpoint)
						padding 0
						min-height 260px
						flex-direction row
					.image
						order 3
						+from-width($mobile_breakpoint)
							order 1
							padding 0 20px
							width 35%
							position relative
						img
							margin 0 auto
							+from-width($mobile_breakpoint)
								absolute bottom 0 left 10px
								max-width 95%
					.text
						text-align center
						order 1
						+from-width($mobile_breakpoint)
							order 2
							text-align left
							width 45%
							padding 80px 0
					.cta
						order 2
						text-align center
						padding 30px 0 40px 0
						+from-width($mobile_breakpoint)
							order 3
							text-align right
							width 20%
							padding 120px 40px 120px 0