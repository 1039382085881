$arrow_width = 20px
$arrow_height = 20px
$arrow_thickness = 3px

.slick-slider
	.slick-slide
		outline none !important

.slick-dotted.slick-slider
	margin-bottom 0

.header-banner, .testimonial-slider
	> .slick-arrow
		&.slick-next, &.slick-prev
			&:before
				content ''
				width $arrow_width
				height $arrow_height
				border-top $arrow_thickness solid #fff				
				opacity 1
				display block
		&.slick-prev
			&:before
				border-left $arrow_thickness solid #fff
				transform rotate(-45deg)
		&.slick-next
			&:before
				border-right $arrow_thickness solid #fff
				transform rotate(45deg)
	.slick-arrow
		absolute top 50%
		transform translateY(-50%)
		&.slick-prev
			left 5%
			z-index 10
		&.slick-next
			right 5%
	ul.slick-dots
		bottom 20px
		text-align left
		width 80%
		left 50%
		transform translateX(-50%)
		+from-width($mobile_breakpoint)
			bottom 20%
		li
			margin 0 12px 0 0
			&.slick-active
				button:before
					opacity 1
			button
				width 24px
				height 16px
				&:before
					content ''
					width 24px
					height 16px
					background-color #fff
					border-radius 8px
					opacity 0.5
.testimonial-slider
	ul.slick-dots
		text-align right
		width 90%
		+from-width($mobile_breakpoint)
			bottom 10%
#testimonialSlider
	position relative
	&.slick-dotted
		margin-bottom 0
	.slick-arrow
		&.slick-next, &.slick-prev
			absolute top 50%
			transform translateY(-50%)
			width 40px
			height 40px
			&:before
				color #fff
				font-size 40px
				content ''
				opacity 1
				display block
				font-weight bold
				font-family FontAwesome
		&.slick-prev
			z-index 5
			left -20px
			+from-width($mobile_breakpoint)
				left 0px
			&:before
				content '\f104'
		&.slick-next
			right -20px
			+from-width($mobile_breakpoint)
				right 0px
			&:before
				content '\f105'
// #reasonSlider
// 	&.slick-dotted
// 		margin-bottom 0
// 	.slick-arrow
// 		&.slick-next, &.slick-prev
// 			absolute bottom 0px
// 			top initial
// 			width 50px
// 			height 50px
// 			border-radius 100px
// 			background-color $orange
// 			z-index 5
// 			+from-width($mobile_breakpoint)
// 				width 70px
// 				height 70px
// 				bottom 50px
// 			&:before
// 				color #fff
// 				font-size 40px
// 				content ''
// 				opacity 1
// 				display block
// 				font-family FontAwesome
// 		&.slick-prev
// 			left 50%
// 			margin-left -30px
// 			transform translateX(-50%)
// 			+from-width($mobile_breakpoint)
// 				transform translateX(0%)
// 				left 200px
// 				margin-left 0
// 			&:before
// 				content '\f104'
// 		&.slick-next
// 			right initial
// 			left 50%
// 			transform translateX(-50%)
// 			margin-left 30px
// 			+from-width($mobile_breakpoint)
// 				margin-left 0
// 				transform translateX(0%)
// 				left 290px
// 			&:before
// 				content '\f105'