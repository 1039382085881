.promotion
	main
		.content-wrapper
			position relative
			margin-top 30px
			padding-bottom 100px
			+from-width($mobile_breakpoint)
				margin-top 50px
				padding-bottom 50px
				padding-left 80px
			.social-media-sidebar
				text-align center
				margin-bottom 30px
				+from-width($mobile_breakpoint)
					absolute top left
					text-align left
					margin-bottom 0
				&.floating
					+from-width($mobile_breakpoint)
						fixed top 150px left 20px
				a
					display inline-block
					margin 0 10px
					+from-width($mobile_breakpoint)
						margin 0 0 30px 0
						display block
			.content
				.text
					+from-width($mobile_breakpoint)
						padding 0 90px
				img
					margin 30px auto
					+from-width($mobile_breakpoint)
						margin 60px auto
				h3
					margin 50px 0 30px 0
					+from-width($mobile_breakpoint)
						padding 0 90px
				table
					margin-bottom 20px
					td
						padding 10px
						color #757F8F
		.related-content-wrapper
			center(1360px, pad:20px)
			border-top 4px solid #E6EBF3
			padding-top 60px
			padding-bottom 60px
			margin-bottom 50px
			a.related-content
				cf()
				display block
				+from-width($mobile_breakpoint)
					center(77%)
				&:hover
					.no-touch &
						.image
							img
								transform scale(1.15)
				.text
					margin-bottom 20px
					+from-width($mobile_breakpoint)
						padding-top 100px
						margin-bottom 0
				.image 
					div
						max-width 410px
						margin 0 auto
						overflow hidden
				.text, .image
					+from-width($mobile_breakpoint)
						col(1/2)
					p
						font-size 20px
					h6
						font-family 'AzoSans Bold', sans-serif
					h2
						margin-bottom 40px
					img
						margin 0 auto
						transition 1s ease-in-out

main.news-activities-inner
	.content-wrapper
		position relative
		margin-top 30px
		padding-bottom 50px
		+from-width($mobile_breakpoint)
			margin-top 50px
			padding-left 80px
		.social-media-sidebar
			text-align center
			margin-bottom 30px
			+from-width($mobile_breakpoint)
				absolute top left
				text-align left
				margin-bottom 0
			&.floating
				+from-width($mobile_breakpoint)
					fixed top 150px left 20px
			a
				display inline-block
				margin 0 10px
				+from-width($mobile_breakpoint)
					margin 0 0 30px 0
					display block
		.content
			.text
				+from-width($mobile_breakpoint)
					padding 0 90px
			img
				margin 30px auto
				+from-width($mobile_breakpoint)
					margin 60px auto
			h3
				margin 50px 0 30px 0
				+from-width($mobile_breakpoint)
					padding 0 90px
			table
				margin-bottom 20px
				td
					padding 10px
					color #757F8F
	.related-content-wrapper
		center(1360px, pad:20px)
		border-top 4px solid #E6EBF3
		padding-top 60px
		padding-bottom 60px
		margin-bottom 50px
		a.related-content
			cf()
			display block
			+from-width($mobile_breakpoint)
				center(77%)
			&:hover
				.no-touch &
					.image
						img
							transform scale(1.15)
			.text
				margin-bottom 20px
				+from-width($mobile_breakpoint)
					padding-top 100px
					margin-bottom 0
			.image 
				div
					max-width 410px
					margin 0 auto
					overflow hidden
			.text, .image
				+from-width($mobile_breakpoint)
					col(1/2)
				p
					font-size 20px
				h6
					font-family 'AzoSans Bold', sans-serif
				h2
					margin-bottom 40px
				img
					margin 0 auto
					transition 1s ease-in-out

.news-activities
	main
		.content-wrapper
			margin 40px auto
			+from-width($mobile_breakpoint)
				margin 100px auto 0 auto
			h2
				margin-bottom 20px
			.news-activities-listing
				cf()
				padding 20px 0
				+from-width($mobile_breakpoint)
					center(80%)
					padding 40px 0
				a
					margin-bottom 45px
					display block
					+from-width($mobile_breakpoint)
						col(1/2, cycle:2)
					&:hover
						.no-touch &
							img
								transform scale(1.15)
					.thumbnail
						overflow hidden
						// border-radius 10px
						margin-bottom 15px
						img
							transition 1s ease-in-out
					h3
						margin-bottom 15px
						font-size 24px
						line-height 24px
					h6
						color #757F8F
			.pagination
				margin-bottom 50px
				+from-width($mobile_breakpoint)
					margin-bottom 100px
				ul
					margin 0
					padding 0
					text-align center
					list-style none
					li
						display inline-block
						margin 0 5px
						a, span
							display block
							text-align center
							font-size 18px
							line-height 24px
							font-family 'Futura Extra Bold', sans-serif
							width 50px
							height 50px
							padding-top 10px
							// padding 9px 14px
							border 4px solid #E6EBF3
							border-radius 6px
							&.prev, &.next
								border-color #fff
								width 60px
							&.current
								color #fff
								background-color $darkBlue
								border-color $darkBlue
						a
							&:hover
								.no-touch &
									background-color $darkBlue
									color #fff
									border-color $darkBlue
						span
							color #E6EBF3
							border-color #fff