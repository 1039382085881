
/* Import / Vendor */
@import '../css/vendor/*.css'

normalize-css()
/* Disable vendor prefixing for nib() */
flex-version = flex
support-for-ie = false
vendor-prefixes = official

/* Variable/Settings */
@import 'global/*.styl'
@import 'global/_variables.styl'

/* Includes */
@import 'includes/*.styl'

/* Modules */
@import 'modules/*.styl'

/* Global App */
*
	box-sizing border-box

img
	max-width 100%
	display block

body
	font-size 14px
	font-family 'AzoSans Regular', sans-serif
	color #4A4A4A
	background #fff
	-webkit-font-smoothing antialiased

p
	line-height 1.5
	font-size 14px
	letter-spacing 0.5px
	margin 0 0 1.5em 0
	&.light
		color #fff
	&.dark
		color #757F8F
	&.dark-blue
		color $darkBlue
	&.small
		font-size 12px
	&.title
		font-size 20px

h1, h2, h3, h4, h5, h6
	margin 0
	&.purple
		color $purple
	&.blue
		color $blue
	&.dark-blue
		color $darkBlue
	&.orange
		color $orange
	&.pink
		color $pink

h1, h2, h3, h4
	color #fff
	font-family 'Futura Extra Bold', sans-serif

h1
	font-size 32px
	line-height 32px
	+from-width($mobile_breakpoint)
		font-size 64px
		line-height 64px

h2
	font-size 28px
	line-height 28px
	+from-width($mobile_breakpoint)
		font-size 48px
		line-height 48px

h3
	font-size 22px
	line-height 22px
	+from-width($mobile_breakpoint)
		font-size 36px
		line-height 36px

h4
	font-size 18px
	line-height 24px

h6
	font-size 14px
	line-height 14px

a
	text-decoration none
	outline none !important
	color #000
	transition 0.3s ease-in-out
	&:hover, &:active, &:visited, &:focus
		text-decoration none
	&.watch-video
		display block
		width 250px
		.icon, span
			display inline-block
			vertical-align middle
		.icon
			box-shadow 0 2px 10px rgba(0, 0, 0, 0.3)
			width 60px
			height 60px
			border-radius 100px
			background-color $orange
			text-align center
			padding-top 20px
			.fa
				font-size 20px
				color #fff
		span
			text-shadow 0 2px 10px rgba(0, 0, 0, 0.3)
			font-size 14px
			margin-left 15px
			color #fff
			font-family 'AzoSans Bold', sans-serif
			letter-spacing 0.8px
	&.primary-btn
		display inline-block
		background-color $orange
		border-radius 40px
		text-align center
		color #fff
		padding 20px 30px
		&.with-shadow
			box-shadow 0 2px 10px 0 rgba(0, 0, 0, 0.25)
		.icon, span
			display inline-block
			vertical-align middle
		.icon
			.fa
				font-size 16px
				color inherit
		span
			font-size 16px
			margin-left 8px
			color inherit
			font-family 'AzoSans Bold', sans-serif
			letter-spacing 0.8px

main
	padding-top 93px
	min-height calc(100vh - 130px)
	position relative
	+from-width($mobile_breakpoint)
		padding-top 106px
		min-height calc(100vh - 76px)
	+from-width($tablet_breakpoint)
		padding-top 109px

.content-wrapper
	center(1360px, pad:20px)
