section.testimonials
	background-color $orange
	padding 40px 0 70px 0
	position relative
	margin-top 100px
	+from-width($mobile_breakpoint)
		margin-top 150px
	.content-wrapper
		.icon
			width 170px
			absolute top -85px left 50%
			transform translateX(-50%)
		h2
			text-align center
			margin 60px 0 30px 0
		.testimonials
			padding 0 10px
			+from-width($mobile_breakpoint)
				padding 0 40px
			.testimonial
				position relative
				padding-top 64px
				margin 0 10px
				.image
					width 128px
					absolute top 0px left 50%
					transform translateX(-50%)
					img
						margin 0 auto
				.bg
					background-color #fff
					border-radius 10px
					padding 90px 20px 20px 20px
					text-align center
					h3
						+from-width($mobile_breakpoint)
							font-size 24px
							line-height 24px
					span
						display block
						color $blue
						font-family 'AzoSans Bold', sans-serif
						letter-spacing 0.8px
						margin 40px auto 20px auto

.testimonial-popup
	max-width 850px
	margin 80px auto 0 auto
	+from-width($mobile_breakpoint)
		margin 40px auto 0 auto
	.content-wrapper
		border-radius 6px
		background-color #fff
		text-align center
		padding 0 20px 50px 20px
		.image
			width 128px
			margin -64px auto 35px auto
			img
				margin 0 auto
		p.title
			font-size 20px
		.content
			p
				center(630px)
				color #757F8F
	.mfp-close
		top -40px
		color #fff
		opacity 1
		text-transform uppercase
		width 100px
		font-family 'AzoSans Bold', sans-serif
		font-size 16px
		letter-spacing 0.8px
		span
			display inline-block
			vertical-align middle
			font-size 25px