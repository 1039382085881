footer
	+from-width($mobile_breakpoint)
		background-color $blue
		position relative
	&:after
		+from-width($mobile_breakpoint)
			content ''
			width 50%
			z-index 1
			height 100%
			background-color $darkBlue
			absolute top right
			display block
	.content-wrapper
		cf()
		padding 0
		+from-width($mobile_breakpoint)
			position relative
			z-index 2
			padding 0 20px
		.copyright
			font-size 12px
			color $darkBlue
			background-color $blue
			padding 20px
			+from-width($mobile_breakpoint)
				span(0.6)
				// margin-top 10px
				padding 31px 0
		.connect-with-us
			cf()
			padding 20px
			background-color $darkBlue
			+from-width($mobile_breakpoint)
				span(0.4)
				padding 20px 0px 20px 25px
			span
				color #fff
				display block
				float left
				margin-top 10px
			.social-media
				float right
				a
					display inline-block
					margin-right 25px
					color #fff
					font-size 30px
					&:last-child
						margin-right 0