header
	fixed top 0 left 0
	width 100%
	z-index 994
	.top-info
		background-color $blue2
		padding 8px 0
		.content-wrapper
			color $darkBlue
			font-size 12px
			font-family 'AzoSans Bold', sans-serif
			letter-spacing 0.8px
			opacity 0.75
			text-align right
			a
				color inherit
				&:hover
					.no-touch &
						text-decoration underline
	.top-navigation
		background-color $blue
		padding 17px 0
		.content-wrapper
			cf()
			.logo
				+from-width($mobile_breakpoint)
					col(0.16)
				a
					width 120px
					display block
					+from-width($mobile_breakpoint)
						width 150px
					+from-width($tablet_breakpoint)
						width 186px
			nav
				display none
				absolute top 93px left 0
				width 100%
				height 100vh
				+from-width($mobile_breakpoint)
					col(0.84)
					margin-right 0
					height auto
					position initial
					top 0
					display block
				ul
					list-style none
					padding 0
					margin 0
					li
						display block
						position relative
						+from-width($mobile_breakpoint)
							display inline-block
							padding 14px 0
						&:last-child
							a
								border-bottom 0px
								margin-right 0
						&#menu-item-41
							+from-width($mobile_breakpoint)
								float right
								padding 3px 0 0 0
							a
								display block
								+from-width($mobile_breakpoint)
									color #fff
									padding 11px
									background-color $darkBlue
									letter-spacing 0.8px
									border-radius 6px
									font-family 'AzoSans Bold', sans-serif
									text-align center
						&#menu-item-22, &#menu-item-3918
							a
								z-index 4
								+from-width($mobile_breakpoint)
									padding-right 20px
							.fa
								width 40px
								height 40px
								padding-top 10px
								text-align center
								color #fff
								font-size 20px
								absolute top 8px right 10px
								z-index 5
								transition 0.3s ease-in-out
								+from-width($mobile_breakpoint)
									width auto
									height auto
									color $darkBlue
									padding-top 0
									text-align left
									top 10px
									right 20px
								&.expand
									transform rotate(180deg)
									+from-width($mobile_breakpoint)
										transform rotate(0deg)
							&.clicked
								ul.sub-menu
									visibility visible
									opacity 1
									transform translate(-50%, 0px)
						a
							font-size 14px
							font-family 'AzoSans Medium', sans-serif
							display block
							background-color $darkBlue
							color #fff
							padding 20px
							position relative
							z-index 3
							border-bottom 1px solid #979797
							+from-width($mobile_breakpoint)
								color $darkBlue
								border-bottom 0px
								padding 0
								margin-right 20px
								background-color unset
								font-size 11px
							+from-width($tablet_breakpoint)
								font-size 14px
						> a:hover
							.no-touch &
								// text-decoration underline
								color #fff
						ul.sub-menu
							display none
							border-bottom 1px solid #979797
							+from-width($mobile_breakpoint)
								border-bottom none
								display block
								opacity 0
								visibility hidden
								transition 0.3s ease-in-out
								absolute top 42px left 50%
								transform translate(-50%, -10px)
								width 130%
								background-color #fff
								box-shadow 0 2px 10px 0 rgba(0,0,0,0.25)
							&:before
								+from-width($mobile_breakpoint)
									background url('../img/dropdown-bg.png') no-repeat 100% 0%
									width 100%
									height 15px
									content ''
									absolute top -15px left 0
							li
								width 100%
								display block
								+from-width($mobile_breakpoint)
									padding 20px 20px 0 20px
								&:last-child
									+from-width($mobile_breakpoint)
										padding-bottom 20px
									a
										padding-bottom 20px
										+from-width($mobile_breakpoint)
											padding-bottom 0
								a
									text-transform uppercase
									padding 20px 20px 0px 35px
									background-color #1e242d
									border-bottom 0px
									+from-width($mobile_breakpoint)
										background-color unset
										padding 0
									&:hover
										.no-touch &
											color $blue
			.mbl-menu
				display block
				width 20px
				height 20px
				absolute top 50px right 20px
				z-index 999999
				cursor pointer
				transition 0.3s ease-in-out
				+from-width($mobile_breakpoint)
					display none
				&.clicked
					transform rotate(270deg)
					span
						&:first-child
							top 10px
							transform rotate(45deg)
						&:nth-child(2)
							opacity 0
						&:last-child
							top 10px
							transform rotate(-45deg)
				span
					transition 0.3s ease-in-out
					display block
					width 100%
					height 2px
					background $darkBlue
					border-radius 3px
					absolute left 0px
					&:first-child
						top 0px
					&:nth-child(2)
						top 9px
					&:last-child
						top 18px